<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Import
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        depressed
        color="secondary"
        @click="startUpload()"
      >
        <v-icon
          x-small
          left
        >fas fa-play</v-icon>
        Start
      </v-btn>
    </v-row>

    <v-row v-if="product" class="mb-3">
      <v-col>
        <span>{{ done + 1 }}/{{ selected.length }} - {{ product.code }} - {{ product.name }}</span>
      </v-col>
    </v-row>

    <v-alert
      v-if="flash.message"
      :color="flash.color"
      class="mb-3"
      dark
    >{{ flash.message }}</v-alert>


    <upload-dialog
      v-model="uploadDialog"
      :suppliers="suppliers"
      @upload="afterUpload"
    ></upload-dialog>

    <what-todo-dialog
      v-model="whatTodoDialog"
      :supplier="supplier"
      @products="selectProductImport"
      @prices="selectPriceImport"
    ></what-todo-dialog>
    
    <products-dialog
      v-model="productsDialog"
      :supplier-id="supplierId"
      :products="products"
      @import="startImport"
    ></products-dialog>

    <product-import
      v-if="product"
      :product="product"
      :supplier-id="supplierId"
      @next="nextProductImport"
    ></product-import>

    <prices-import-dialog
      v-model="pricesImportDialog"
      :supplier-id="supplierId"
      :prices-csv="pricesCsv"
      :prices-total="pricesTotal"
      @done="donePricesImport"
    ></prices-import-dialog>

  </v-card>
  
</template>

<script>
/* eslint-disable */
import { ref, reactive, computed, inject, nextTick } from 'vue'
import { UploadDialog, ProductsDialog, WhatTodoDialog, ProductImport, PricesImportDialog } from '@/components/import'
import goTo from 'vuetify/lib/services/goto'

export default {
  components: {
    UploadDialog,
    ProductsDialog,
    WhatTodoDialog,
    ProductImport,
    PricesImportDialog,
  },
  setup() {
    const api = inject('api')


    const uploadDialog = ref(false)
    const whatTodoDialog = ref(false)
    const productsDialog = ref(false)
    const productImport = ref(false)
    const pricesImportDialog = ref(false)

    const supplierId = ref()
    const products = ref([])
    const pricesCsv = ref()
    const pricesTotal = ref()

    const selected = ref([])
    const product = computed(() => selected.value.find(sel => sel.status !== 'done'))
    const done = computed(() => selected.value.filter(sel => sel.status === 'done').length)
    
    const { items: suppliers } = api.collection('suppliers').all()
    const supplier = computed(() => suppliers.value.find(supplier => supplier.id === supplierId.value))

    const flash = reactive({ message: null, color: 'primary' })

    function startUpload() {
      selected.value = []
      uploadDialog.value = true
    }

    function afterUpload(data) {
      supplierId.value = data.supplier_id
      products.value = data.products
      pricesCsv.value = data.prices_csv
      pricesTotal.value = data.prices_total
      whatTodoDialog.value = true
    }

    function selectProductImport() {
      productsDialog.value = true
    }

    function selectPriceImport() {
      pricesImportDialog.value = true
    }

    function startImport(selection) {
      selected.value = selection
    }

    function nextProductImport() {
      const index = selected.value.findIndex(sel => sel.status !== 'done')
      if (index != -1) {
        const temp = [...selected.value] // needed to trigger reactivity
        temp[index].status = 'done'
        selected.value = temp

        if (temp.findIndex(sel => sel.status !== 'done') == -1) {
          flashMessage(`Product import done!`)
        }

      }
      nextTick(() => {
        goTo(0)
      })
    }

    function flashMessage(message, timeout = 3500) {
      flash.message = message
      setTimeout(() => flash.message = null, timeout)
    }

    function donePricesImport(result) {
      flashMessage(`Price update done! ${result.total} prices (${result.updated} updated)`)
    }

    return {
      uploadDialog,
      suppliers,
      startUpload,
      afterUpload,

      productsDialog,
      supplierId,
      supplier,
      products,
      product,

      whatTodoDialog,
      selectProductImport,
      selectPriceImport,

      startImport,
      productImport,
      selected,

      nextProductImport,
      done,

      pricesImportDialog,
      pricesCsv,
      pricesTotal,

      donePricesImport,
      flash,
    }
  },
}
</script>


<style scoped>


</style>
