<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Import</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12 mb-3">
        <div class="text-h6 text-center">{{ pricesTotal }} variants found</div>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="close()"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="importPrices()"
          :disabled="isBusy"
        >Import</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
  /* eslint-disable */
import { inject, ref, watch } from 'vue'

export default {
  components: {
  },
  props: {
    value: Boolean,
    supplierId: Number,
    pricesCsv: String,
    pricesTotal: Number,

  },
  setup(props, context) {

    
    const api = inject('api')
    const loader = inject('loader')

    const dialog = ref(props.value)

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function close() {
      dialog.value = false
      context.emit('close')
    }

    const importModel = api.model('product-variant-suppliers/import', {
      attributes: {
        total_rows: 0,
        total_updated: 0,
      },
    })
    const { isBusy } = importModel


    function importPrices() {
      loader.show()
      importModel.setAttributes({
        data_url: props.pricesCsv,
        supplier_id: props.supplierId,
      }).create().then(() => {
        context.emit('done', {
          updated: importModel.getAttribute('total_updated'),
          total: importModel.getAttribute('total_rows'),
        })
        close()
      }).finally(() => {
        loader.hide()
      })
    }

    return {
      dialog,
      close,
      importPrices,
      isBusy
    }

  }



}




</script>

