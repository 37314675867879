<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Upload CSV</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <v-form
          ref="uploadForm"
          @submit.prevent
        >
          <v-row>
            <v-col cols="10">

              <file-drop-input
                outlined
                v-model="file"
                :error-messages="errors.data_url"
                clear-icon="fas fa-times"
                accept="text/csv"
                label="File input"
              ></file-drop-input>
            </v-col>
            <v-col cols="2">
              <v-text-field
                outlined
                v-model="model.csv_separator"
                :error-messages="errors.csv_separator"
                label="Separator"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-select
            v-if="errors.supplier_id"
            outlined
            clearable
            clear-icon="fas fa-times"
            v-model="model.supplier_id"
            :items="suppliers"
            item-text="name"
            item-value="id"
            :error-messages="errors.supplier_id"
          ></v-select>

        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="close()"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="upload()"
          :disabled="isBusy"
        >Upload</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
import { inject, ref, watch } from 'vue'
import FileDropInput from '@/components/FileDropInput'
import { readFileAsDataUrl } from '@/utils'

export default {
  components: {
    FileDropInput,
  },
  props: {
    value: Boolean,
    suppliers: Array,

  },
  setup(props, context) {
    
    const api = inject('api')
    const dialog = ref(props.value)
    const loader = inject('loader')

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    const file = ref()

    const uploadModel = api.model('products/upload', {
      attributes: {
        data_url: null,
        csv_separator: ';',
      }
    })
    const { attributes: model, errors } = uploadModel
    const isBusy = ref(false)

    function upload() {
      isBusy.value = true
      loader.show()

      uploadModel.clearErrors()

      readFileAsDataUrl(file.value).then(url => {
        uploadModel.setAttribute('data_url', url)
      }).catch(() => {
        uploadModel.setAttribute('data_url', null)
      }).finally(() => {

        uploadModel.create().then(() => {

          context.emit('upload', {
            supplier_id: uploadModel.getAttribute('supplier_id'),
            products: uploadModel.getAttribute('products'),
            prices_csv: uploadModel.getAttribute('prices_csv'),
            prices_total: uploadModel.getAttribute('prices_total'),
          })

          close()

        }).finally(() => {

          isBusy.value = false
          loader.hide()
        })
      })
    }

    function close() {
      file.value = null
      uploadModel.clear()
      dialog.value = false
    }

    return {
      dialog,
      file,
      model,
      errors,
      upload,
      close,
      isBusy,
    }

  }



}




</script>

