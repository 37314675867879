<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    persistent
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Import</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">
        <div class="text-h6 text-center">Supplier: <span v-if="supplier">{{ supplier.name }}</span></div>
      </v-card-text>

      <v-card-actions class="px-6 mt-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="mx-auto px-5"
          large
          outlined
          depressed
          @click="close()"
        >Cancel</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="products()"
        >Products</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="prices()"
        >Prices</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
  /* eslint-disable */
import { inject, ref, watch } from 'vue'

export default {
  components: {
  },
  props: {
    value: Boolean,
    supplier: Object,

  },
  setup(props, context) {
    
    const api = inject('api')
    const dialog = ref(props.value)

    watch(() => props.value, val => {
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    function close() {
      dialog.value = false
    }

    function products() {
      context.emit('products')
      close()
    }

    function prices() {
      context.emit('prices')
      close()
    }

    return {
      dialog,
      close,
      products,
      prices,
    }

  }



}




</script>

