<template>
  <v-dialog
    v-model="dialog"
    max-width="900"
    persistent
  >
    <v-card>
      <v-toolbar color="primary" dark flat short class="px-2">
        <v-toolbar-title>Products</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="close()" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">

        <v-text-field
          v-model="search"
          append-icon="fas fa-search"
          label="Search"
          single-line
          hide-details
          class="mb-4"
        ></v-text-field>
        
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="products"
          :search="search"
          show-select
          item-key="code"
        >
      
          <template v-slot:item.has_size="{ item }">
            <v-icon :color="item.has_size ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
          </template>

          <template v-slot:item.is_new_product="{ item }">
            <v-icon :color="item.is_new_product ? 'secondary' : 'grey'" x-small>fas fa-circle</v-icon>
          </template>

          <template v-slot:footer.prepend>
            <v-btn
              v-if="selected.length"
              @click="startImport()"
              color="primary"
            >Import</v-btn>
          </template>

        </v-data-table>

      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="close()"
        >Cancel</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>

</template>

<script>
  /* eslint-disable */
import { inject, ref, watch } from 'vue'

export default {
  components: {
  },
  props: {
    value: Boolean,
    supplierId: Number,
    products: Array,

  },
  setup(props, context) {
    
    const api = inject('api')
    const dialog = ref(props.value)
    const selected = ref([])
    const search = ref()

    watch(() => props.value, val => {
      if (val) {
        selected.value = []
      }
      dialog.value = val
    })

    watch(dialog, val => {
      context.emit('input', val)
    })

    const headers = [
      { text: 'Code', value: 'code' },
      { text: 'Name', value: 'name' },
      { text: 'Has size', value: 'has_size', align: 'center' },
      { text: 'Is new product', value: 'is_new_product', align: 'center' },
      { text: 'Current variants', value: 'cur_variants', align: 'center' },
      { text: 'New variants', value: 'new_variants', align: 'center' },
    ]




    function close() {
      context.emit('close')
      dialog.value = false
    }

    function startImport() {
      // console.log(selected.value)
      context.emit('import', selected.value)
      close()
    }


    return {
      dialog,
      close,
      headers,
      selected,
      startImport,
      search,
    }

  }



}




</script>

